<template>
  <UiButton icon severity="secondary" class="size-12 min-w-12" @click.stop="goBackHandler">
    <slot>
      <ThemeIcon name="arrow-left" class="size-4 text-icon-secondary" />
    </slot>
  </UiButton>
</template>

<script setup lang="ts">
import { type RouteLocationRaw, useRouter } from 'vue-router'
import { ThemeIcon } from '@/5_entities/Configuration'
import { useGoBack } from '@/6_shared/composables'
import { UiButton } from '@/6_shared/ui'

type PropType = {
  static?: boolean
  to?: RouteLocationRaw
}

const props = withDefaults(defineProps<PropType>(), {
  static: false,
  to: undefined
})

const router = useRouter()
const { goBack } = useGoBack()

const goBackHandler = () => {
  if (props.static) return
  if (props.to) {
    return router.push(props.to)
  }

  goBack()
}
</script>
