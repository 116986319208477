import type { RouteRecordRaw } from 'vue-router'

export const EventViewTimetableRoute: RouteRecordRaw = {
  path: '/event/:id/timetable',
  name: 'EventViewTimetable',
  component: () => import('../ui/EventViewTimetable.vue'),
  meta: {
    layout: 'empty',
    disableOnThemeConstructor: true
  }
}
