import type { RouteRecordRaw } from 'vue-router'

export const SeanceViewRoute: RouteRecordRaw = {
  path: '/seance/:id',
  name: 'SeanceView',
  component: () => import('../ui/SeanceView.vue'),
  meta: {
    layout: 'empty',
    disableOnThemeConstructor: true
  }
}
