import type { RouteRecordRaw } from 'vue-router'

export const SearchCategoryViewRoute: RouteRecordRaw = {
  path: '/search/:id',
  name: 'SearchCategoryView',
  component: () => import('../ui/SearchCategoryView.vue'),
  meta: {
    disableOnThemeConstructor: true
  }
}
