<template>
  <div />
</template>

<script lang="ts" setup>
import bridge from '@vkontakte/vk-bridge'
import { onBeforeMount } from 'vue'
import { getParsedInitialParamsFromQuery } from '@/5_entities/Configuration'
import { useLocation, type Location } from '@/5_entities/Location'
import { type UserDTO, UserDTOAdapter, useUser } from '@/5_entities/User'
import { getMilliseconds } from '@/6_shared/lib'

type EmitType = {
  (e: 'userInitialized'): void
}

const emit = defineEmits<EmitType>()

const { authUser } = useUser()

const { setLocationOnInit } = useLocation()

const getUserFromQuery = () => {
  const query = getParsedInitialParamsFromQuery()

  return new UserDTOAdapter({
    userId: query.userId as string,
    location: query?.location as string,
    ageInterval: query?.ageInterval as string,
    sex: query?.sex as string,
    coords: query?.coords as string
  })
}

/**
 * проверяем, что открыты в vk Apps
 */
const checkIsVk = () => {
  const query = getParsedInitialParamsFromQuery()

  return 'isVk' in query
}

/**
 * пытаемся заинтить вк
 */
const tryToInitVk = async (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    bridge
      .send('VKWebAppInit')
      .then(() => resolve(true))
      .catch(() => reject(false))

    setTimeout(() => reject(false), getMilliseconds.inSeconds(5))
  })
}
/**
 * пробуем получить email пользователя из vk API.
 * если встроены не в Vk Apps и нам не запретелили
 * @returns email:string | ''
 */
const tryToGetUserIdFromVkApp = async (): Promise<string> => {
  return await bridge
    .send('VKWebAppGetEmail')
    .then((data) => `userVk:${data.email}`)
    .catch(() => '')
}

const setLocation = (userData: UserDTO) => {
  const { location, adjustLocation } = userData

  setLocationOnInit(location as Location, adjustLocation)
}

onBeforeMount(async () => {
  const isVk = checkIsVk()
  const userDTO = getUserFromQuery()

  if (isVk) {
    const isVkInitialized = await tryToInitVk()
    const needToRequestVkUserEmail = isVkInitialized && !userDTO.userId

    needToRequestVkUserEmail && (userDTO.userId = await tryToGetUserIdFromVkApp())
  }

  const userData = await authUser(userDTO)

  if (!userData) return emit('userInitialized')

  setLocation(userData)
  emit('userInitialized')
})
</script>
