import { VenueAdapter } from '@/5_entities/Venue'
import type { Seance, SeanceDTO } from './type'

export class SeanceAdapter implements Seance {
  public id: Seance['id']
  public beginsAt: Seance['beginsAt']
  public endsAt: Seance['endsAt']
  public priceRange: Seance['priceRange']
  public venue: Seance['venue']
  public ticketsLeft: Seance['ticketsLeft']
  public forceDisplay: Seance['forceDisplay']

  constructor(data: SeanceDTO) {
    this.id = data.id
    this.beginsAt = this._getUtcDate(data.beginsAt).toString()
    this.endsAt = this._getUtcDate(data.endsAt || data.beginsAt).toString()
    this.priceRange = data.priceRange
    this.venue = data.venue && new VenueAdapter(data.venue)
    this.ticketsLeft = data?.ticketsLeft || 0
    this.forceDisplay = data.forceDisplay
  }

  private _getUtcDate(value: Date | string) {
    const date = typeof value === 'string' ? new Date(value) : value

    return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000).toString()
  }
}
