<template>
  <div>
    <TransitionFade :delay="{ enter: 500, leave: 500 }" :duration="{ enter: 700, leave: 200 }">
      <div
        v-if="appIsReady && locationIsNotDefined"
        class="rounded-2xl border border-secondary bg-primary p-5 shadow-secondary_lg"
        v-bind="$attrs"
      >
        <UiTitle severity="h5" class="mb-3"> Ваш город<br />{{ currentLocation?.name }}? </UiTitle>
        <div class="flex gap-3">
          <UiButton size="small" severity="secondary" class="px-5" @click="defineLocation">
            Нет
          </UiButton>
          <UiButton size="small" class="px-6" @click="applyCity"> Да </UiButton>
        </div>
        <UiIcon
          solid
          name="popover-triangle"
          class="absolute right-2 top-full h-4 w-7 -translate-y-1 text-[var(--backgroundColor-primary)]"
        />
      </div>
    </TransitionFade>
    <UiDialog v-model="showChooseLocation" class="bg-primary !p-0">
      <ChooseLocation @close="showChooseLocation = false" />
    </UiDialog>
  </div>
</template>

<script setup lang="ts">
import { TransitionFade } from '@morev/vue-transitions'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useConfiguration } from '@/5_entities/Configuration'
import { useLocation } from '@/5_entities/Location'
import { UiButton, UiTitle, UiIcon, UiDialog } from '@/6_shared/ui'
import ChooseLocation from './ChooseLocation.vue'

const { appIsReady } = storeToRefs(useConfiguration())
const { locationIsNotDefined, currentLocation } = storeToRefs(useLocation())
const { setLocation } = useLocation()

defineOptions({
  inheritAttrs: false
})

const showChooseLocation = ref(false)

const defineLocation = () => (showChooseLocation.value = true)

const applyCity = () => {
  setLocation(currentLocation.value!)
  locationIsNotDefined.value = false
}
</script>
