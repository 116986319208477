import type { RouteRecordRaw } from 'vue-router'

export const ConfigurationThemeConstructorRoute: RouteRecordRaw = {
  name: 'ConfigurationThemeConstructorView',
  path: '/service/configuration/theme-constructor',
  component: () => import('../ui/ThemeConstructorView.vue'),
  meta: {
    layout: 'empty',
    disableOnThemeConstructor: true
  }
}
