import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref } from 'vue'
import type { Seance } from '@/5_entities/Seance'
import { usePageLoading } from '@/6_shared/composables'
import type { Pagination } from '@/6_shared/model'
import { eventService } from '../api'
import { EventAdapter } from './adapter'
import type { Event, EventDTO } from './type'

export const useEvent = defineStore('event', () => {
  const event = ref<Undefinable<Event>>(undefined)

  const setEvent = (eventData: EventDTO) => (event.value = new EventAdapter(eventData))

  const { initialized: eventInitialized, load: loadEvent } = usePageLoading()
  const getEvent = (id: number) => {
    loadEvent(async () => {
      const event = await eventService.get(id)
      setEvent(event)
    })
  }

  const deleteEventSeance = (id: Seance['id']) => {
    if (!event.value) return

    const index = event.value.seances.findIndex((seance) => seance.id === id)

    if (index < 0) return

    event.value.seances.splice(index, 1)
  }

  //seances
  const pagination = computed((): Undefinable<Pagination> => event.value?.seancesPagination)
  const allSeancesLoaded = computed(
    () => !!pagination.value && pagination.value.currentPage >= pagination.value.pagesCount
  )

  const loadNextSeancePage = async () => {
    if (!event.value || !pagination.value) return

    const newSeances = await eventService.loadSeances(
      event.value.id,
      pagination.value.currentPage + 1
    )

    event.value.addSeancesNextPage && event.value.addSeancesNextPage(newSeances)
  }

  //all events
  const events = ref<Undefinable<Event[]>>([])

  const { initialized: eventsInitialized, load: loadEvents } = usePageLoading()
  const getAllEvents = () => {
    loadEvents(
      async () => {
        events.value = (await eventService.getALl()).map((item) => new EventAdapter(item))
      },
      (error: any) => {
        console.error(error)
        throw error
      }
    )
  }

  return {
    eventInitialized,
    event,
    deleteEventSeance,
    getEvent,
    //all events
    events,
    eventsInitialized,
    getAllEvents,
    //seances
    pagination,
    allSeancesLoaded,
    loadNextSeancePage
  }
})
