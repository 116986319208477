import { plugin as vueTransitionsPlugin } from '@morev/vue-transitions'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import type { App as AppType } from 'vue'
import { createYmaps } from 'vue-yandex-maps'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import { AppConfigError } from '@/3_widgets/Configuration'
import { checkConfig } from '@/6_shared/config'
import { getMilliseconds, toastifyClasses } from '@/6_shared/lib'
import App from './App.vue'
import { router } from './providers'
import './styles/index.css'
import '@morev/vue-transitions/styles'
import 'vue3-toastify/dist/index.css'

let app: AppType
try {
  checkConfig()

  app = createApp(App)
    .use(createPinia())
    .use(router)
    .use(vueTransitionsPlugin)
    .use(Vue3Toastify, {
      autoClose: getMilliseconds.inSeconds(3),
      position: 'top-center',
      hideProgressBar: true,
      multiple: true,
      clearOnUrlChange: false,
      transition: 'slide',
      icon: false,
      closeButton: false,
      ...toastifyClasses
    } as ToastContainerOptions)
    .use(
      createYmaps({
        apikey: window.config.VITE_APP_YANDEX_MAPS_KEY
      })
    )
} catch (error: any) {
  const { message } = error

  app = createApp(AppConfigError, { message })
}

export { app }
